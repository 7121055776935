<template>
  <CNStep :steps="steps" :current-step="step" @click="handleClick" />
</template>

<script>
export default {
  name: 'BuilderSteps',
  props: {
    step: { type: Number, default: () => 0 },
  },
  data() {
    return {
      routeStep: {
        ProjectDetails: 1,
        ProjectTeams: 2,
        ProjectScopeWorkflow: 3,
        ProjectContractWorkflow: 4,
        ProjectTemplateCollection: 5,
      },
      steps: [
        {
          name: '1. Project Info',
          icon: 'cilSolidCheck',
          disabled: false,
          route: {
            name: 'ProjectDetails',
            params: { id: this.$route.params.id },
          },
        },
        {
          name: '2. Team Management',
          icon: 'cilDocUser',
          disabled: false,
          route: {
            name: 'ProjectTeams',
            params: { id: this.$route.params.id },
          },
        },
        {
          name: '3. Scope Workflow',
          icon: 'cilSolidUser',
          disabled: false,
          route: {
            name: 'ProjectScopeWorkflow',
            params: { id: this.$route.params.id },
          },
        },
        {
          name: '4. Contract Workflow',
          icon: 'cilDocUser',
          disabled: false,
          route: {
            name: 'ProjectContractWorkflow',
            params: { id: this.$route.params.id },
          },
        },
        {
          name: '5. Manage Documents',
          icon: 'cilListAdd',
          disabled: false,
          route: {
            name: 'ProjectTemplateCollection',
            params: { id: this.$route.params.id, create: undefined },
          },
        },
      ],
    }
  },
  mounted() {
    // let currentRouteStep = this.routeStep[this.$route.name]
    // if (currentRouteStep > this.step) {
    //   this.$router.push(this.steps[this.step]['route'])
    // }
  },
  methods: {
    handleClick(key, item) {
      if (key > this.step) return false
      this.$router.push(item.route)
    },
  },
}
</script>

<style scoped></style>
