export function generateLink(template) {
    const id = template.template_id || template.id;

    switch (template.type) {
        case 'Acknowledge':
            return {
                name: (template.has_attachment || template.standard_template_id) ? 'TemplateRequest' : 'TemplateEditor',
                params: {id},
            };
        case 'Contracts':
            return {
                name: 'TemplateEditor',
                params: {id},
            };
        case 'Request documents':
            return {
                name: (template.has_attachment || template.standard_template_id) ? 'TemplateRequest' : 'TemplateEditor',
                params: {id},
            };
        default:
            return {};
    }
}

export function generateLinkIcon(type) {
    switch (type) {
        case 'package':
            return 'cilFolder';
        case 'scenario':
            return 'cilLayers';
        case 'Acknowledge':
            return 'cilTask';
        case 'Contracts':
            return 'cilDescription';
        case 'Request documents':
            return 'cilShareBoxed';
    }
}
