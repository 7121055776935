<template>
  <div class="panel">
    <div class="panel__buttons-group">
      <slot name="leftButtonsGroup">
        <CButton
          v-for="(button, index) in buttons"
          :key="`${button.text}-${index}`"
          type="button"
          color="primary"
          :variant="button.outline && 'outline'"
          :disabled="button.disabled"
          class="panel__button"
          @click="handleBtnClick(button)"
        >
          {{ button.text }}
        </CButton>
      </slot>
    </div>

    <div class="panel__buttons-group">
      <CButton
        class="panel__button panel__button--delete"
        color="link"
        type="button"
        :disabled="disabledDeleteButton"
        @click="deleteHandler"
      >
        <CIcon name="cilTrash" size="lg" class="panel__button-icon" />
        {{ deleteText }}
      </CButton>
    </div>
  </div>
</template>

<script>
import Gate from '@/services/Gate/Gate'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'FixedBottomPanel',
  inject: ['toast'],
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    /**
     * buttons: [
     *   {
     *      text: String,
     *      outline: Boolean,
     *      disabled: Boolean,
     *      event: Function,
     *   }
     * ]
     */
    deleteText: {
      type: String,
      default: 'Delete project',
    },
    disabledDeleteButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['deleteHandler'],
  methods: {
    async handleBtnClick(btn) {
      let check = this.$route.params.scopeId
        ? await Gate.can('edit', 'scope', this.$route.params.scopeId)
        : await Gate.can('editOrCreate', 'project', this.$route.params.id)

      check ? btn.event() : this.$notify(permissionError)
    },
    async deleteHandler() {
      let check = this.$route.params.scopeId
        ? await Gate.can('delete', 'scope', this.$route.params.scopeId)
        : await Gate.can('delete', 'project', this.$route.params.id)

      check ? this.$emit('deleteHandler') : this.$notify(permissionError)
    },
  },
}
</script>

<style scoped lang="scss">
.panel {
  position: sticky;
  bottom: 0;
  border-top: 2px solid #dee4e8;
  padding-top: 24px;
  padding-bottom: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  background: #ffffff;
  margin-left: 0;
  z-index: 9;

  &__buttons-group {
    display: flex;
    align-items: center;
  }

  &__button {
    &--delete {
      color: #db000b;

      &[disabled] {
        color: #9aa8b5;
      }
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
</style>
