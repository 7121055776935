<template>
  <CModal
    backdrop="static"
    size="lg"
    class="addFromPc"
    :visible="modelValue"
    @click="handleClose"
    @show="handleShow"
  >
    <CModalHeader :close-button="false">
      <CModalTitle>Add file from computer</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p>
        File on computer will be added as acknowledge to the project collections
        list
      </p>
      <CCol>
        <input
          id="file"
          type="file"
          hidden
          accept="application/msword, application/pdf, .docx, .doc, .pdf"
          @change="handleChange($event)"
        />
      </CCol>
      <CCol v-if="Object.keys(errors).length" class="mt-4">
        <CFormText
          v-for="error in errors"
          :key="error"
          style="color: red; font-size: 16px"
        >
          {{ error }}
        </CFormText>
      </CCol>
    </CModalBody>
    <CModalFooter class="mt-4">
      <CRow :xs="{ cols: 2, gutter: 4 }" class="w-100">
        <CCol style="text-align: right">
          <CButton
            color="primary"
            variant="outline"
            class="w-75"
            @click="handleClose"
          >
            Cancel
          </CButton>
        </CCol>
        <CCol style="text-align: left">
          <CButton
            color="primary"
            class="w-75"
            :disabled="loading"
            @click="handleChoose"
          >
            {{ addBtn }}
          </CButton>
        </CCol>
      </CRow>
    </CModalFooter>
  </CModal>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { parseError } from '@/utils/api'
import Converter from '@/utils/templatesConverter/Converter'
const converter = new Converter()

export default {
  name: 'FromPC',
  inject: ['toast'],
  props: {
    modelValue: { type: Boolean, default: false },
    defaultName: { type: String, default: '' },
    type: { type: String, default: 'Acknowledge' },
  },
  emits: ['update:modelValue', 'update'],
  data() {
    return {
      addBtn: 'Add',
      loading: false,
      validator: {},
      errors: {},
      form: {
        file: null,
        type: this.type,
        content: '',
        name: this.defaultName,
        task_name: this.defaultName,
        is_manually: true,
        is_temporary: true,
      },
    }
  },
  computed: {
    modifiedBy() {
      let user = this.$store.getters.user
      return {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        full_name: `${user.first_name} ${user.last_name}`,
      }
    },
  },
  mounted() {},
  methods: {
    clearForm() {
      this.form = {
        file: null,
        type: 'Acknowledge',
        content: '',
        name: this.defaultName,
        is_manually: true,
        is_temporary: true,
      }
    },
    handleShow() {
      this.clearForm()
      this.errors = {}
      this.validator = validator({
        file: [
          rules.required,
          rules.extensions(['.pdf', '.docx']), // TODO add -> '.doc',
          rules.fileSize({ size: 10, type: 'MB' }),
        ],
      })
    },
    handleChoose() {
      this.errors = {}
      document.getElementById('file').click()
    },
    handleChange($event) {
      const files = $event.target.files
      const fileReader = new FileReader()
      fileReader.readAsDataURL(files[0])
      this.form.file = files[0]

      const validationResult = this.validator.validateAll(this.form)
      this.errors = validationResult.validationErrors

      if (!validationResult.hasErrors) {
        converter
          .convertFile(this.form.file)
          .then((res) => {
            this.form.name = this.form.file.name.split('.').shift()
            this.form.content = res.html

            this.handleSave()
          })
          .catch((err) => {
            this.toast('error', 'Something went wrong')
            document.getElementById('file').value = null
          })
      }
    },
    handleClose() {
      this.errors = {}
      this.addBtn = 'Add'
      document.getElementById('file').value = null
      this.clearForm()
      this.$emit('update:modelValue', false)
    },
    handleSave() {
      this.form.file = ''
      this.loading = true
      this.$http.library
        .createTemplate(this.form)
        .then((res) => {
          this.addBtn = 'Add'
          let template = res.data.template
          template.modified_by = this.modifiedBy
          template.template_name = template.name
          template.task_name = template.name
          template.type = this.type
          this.$store.getters.user
          // this.toast('success', res.data.message)
          this.$emit('update', template)
          this.handleClose()
        })
        .catch((err) => {
          err = parseError(err)
          if (err.status === 422) {
            this.errors = err.validationMessages
            if (this.errors.name === 'Such name already exists') {
              this.errors.name =
                'Such name of the document already exists inside the library'
            }
            this.addBtn = 'Try again'
          } else {
            this.toast('error', err.message)
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style>
.addFromPc .modal-title {
  width: 100%;
}

.addFromPc h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #202e39;
}

.addFromPc .modal-header,
.addFromPc .modal-body {
  text-align: center;
}

.addFromPc .modal-content {
  padding: 2.5rem !important;
}
.addFromPc p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
</style>
