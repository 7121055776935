import BaseMethods from '../BaseMethods';
import {getData} from '../utils/DataHandler';

export default new (class ProjectsAdditionalDocuments extends BaseMethods {
    endpointRoute = '/api/v2/projects/:projectId/additional-documents';

    async store(projectId, data) {
        return this.setRouteBindings({projectId}).asFormData().post(this.endpointRoute, data).then(getData);
    }
})();
