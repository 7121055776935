<template>
  <CIcon :name="getIcon(type)" />
</template>

<script>
export default {
  name: 'Icon',
  props: { type: { type: String, default: '' } },
  methods: {
    getIcon(type) {
      switch (type) {
        case 'Acknowledge':
          return 'cilTask'
        case 'Request documents':
          return 'cilShareBoxed'
        case 'Contracts':
          return 'cilDescription'
      }
    },
  },
}
</script>

<style scoped></style>
