import errors from '@/mixin/errors'
import TableCollection from './components/TableCollection'
import Navigation from '@/components/Card/Navigation'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import {isEqual} from '@/utils/helper'
import CDivider from '@/components/CDivider'
import CNInput from '@/components/ui/CNInput/CNInput'
import CNSelect from '@/components/ui/CNSelect/CNSelect'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import BuilderSteps from '@/views/Projects/Project/Components/BuilderSteps'
import FixedBottomPanel from '@/views/Projects/Project/Components/FixedBottomPanel'
import {mapGetters} from 'vuex'
import {permissionError} from '@/services/Notify/Toasts'
import $p from '@/utils/constants/permissions'
import ProjectsAdditionalDocuments from "@/api/v2/endpoints/ProjectsAdditionalDocuments";

export default {
    async beforeRouteLeave() {
        if (
            !this.projectNewName ||
            this.additionalFilesChanged ||
            !this.collectionWasChanged
        ) {
            await this.$refs.confirmDialog
                .confirm({
                    text:
                        'You have unsaved changes.\n' +
                        'Are you sure, you want to leave the page?',
                    confirmText: 'Leave',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        return true
                    } else {
                        return Promise.reject(false)
                    }
                })
        } else {
            return true
        }
    },
    mixins: [errors],
    components: {
        TableCollection,
        Navigation,
        ConfirmDialog,
        CDivider,
        CNInput,
        CNSelect,
        BuilderSteps,
        FixedBottomPanel,
    },
    inject: ['toast', 'loaderFile'],
    async mounted() {
        await this.getAdditionalFiles()
        await this.getProject()
        await this.fetchData()
        await this.setRules()
    },
    data() {
        return {
            errorText: '',
            collectionError: false,
            additionalFiles: [],
            key: 0,
            activeTab: 'Manage Collections',
            links: [
                {
                    name: 'Project Details',
                    route: {
                        name: 'ProjectDetails',
                        params: {id: this.$route.params.id},
                    },
                },
                {
                    name: 'Team & Approvals',
                    route: {
                        name: 'ProjectTeams',
                        params: {id: this.$route.params.id},
                    },
                },
                {name: 'Manage Collections', route: ''},
            ],
            projectName: '',
            collection: [],
            showTemplates: false,
            showTemplatesText: 'View Packages',
            validationErrors: {},
            selectCollection: null,
            folderList: [],
            originFolderList: [],
            loading: false,
            uploading: false,
            project: {},
            defaultName: '',
            additionalFilesChanged: false,
            collectionChanged: null,
            step: 5,
        }
    },
    props: {
        form: {
            type: Object,
            default: () => {
            },
        },
    },
    watch: {
        folderList() {
            this.validateFolderList()
        },
        selectCollection() {
            if (this.selectCollection) {
                this.loading = true
                this.setFoldersFromServer().finally(() => (this.loading = false))
            } else {
                this.folderList = []
                this.originFolderList = []
            }
        },
    },
    computed: {
        ...mapGetters({
            permissions: 'projectPermissions',
            user: 'user',
        }),
        disabled() {
            return !this.permissions.editOrCreate
        },
        canToLibrary() {
            return this.user.can($p.TEMPLATES_VIEW)
        },
        draftTemplates() {
            let arr = []

            this.folderList.forEach((el) => {
                ['primary_contracts', 'secondary_contracts'].forEach((type) => {
                    el[type]?.forEach((c) => {
                        if (c.is_draft) arr.push(c.task_name)
                    })
                })
            })

            return arr.length
                ? 'This Scenario contains templates "' + arr.join('", "') + '" in draft status'
                : ''
        },
        listOfFolders() {
            const isMain = (i) => i.is_main
            const notMain = (i) => !i.is_main
            return this.folderList.map((i) => {
                const primary = [
                    ...i.primary_contracts.filter(isMain),
                    ...i.primary_contracts.filter(notMain),
                ]
                const secondary = [
                    ...i.secondary_contracts.filter(isMain),
                    ...i.secondary_contracts.filter(notMain),
                ]
                return {
                    ...i,
                    primary_contracts: primary,
                    secondary_contracts: secondary,
                }
            })
        },
        collectionWasChanged() {
            return this.collectionChanged === this.selectCollection
        },
        isChanged() {
            return !isEqual(this.folderList, this.originFolderList)
        },
        projectNewName() {
            return this.projectName === this.project.name
        },
        fixedBottomPanelButtons() {
            return [
                {
                    text: 'Cancel',
                    disabled: this.projectNewName && this.project.name,
                    outline: true,
                    event: () => this.handleCancel(),
                },
                {
                    text: 'Save',
                    outline: true,
                    event: () => this.save(false),
                },
                {
                    text: 'Save & next',
                    event: () => this.save(true),
                },
            ]
        },
    },
    methods: {
        showError() {
            this.$notify(permissionError)
        },
        handleClickUpload($event) {
            if (this.uploading || this.disabled) {
                $event.preventDefault()
            }
            if (this.disabled) this.$notify(permissionError)
        },
        async setFoldersFromServer() {
            return await this.$http.projects
                .getProjectCollectionList({
                    params: {
                        collection_id: this.selectCollection,
                        project_id: this.$route.params.id,
                    },
                })
                .then((res) => {
                    this.folderList = res.data.data.folders
                    this.defaultName = res.data.data.name
                    this.originFolderList = this.$deepClone(this.folderList)
                })
        },
        downloadDocument(id, name) {
            this.$http.projects
                .downloadAdditionalDocument(id, {
                    responseType: 'blob',
                })
                .then((response) => {
                    this.toast('success', 'Additional document has been downloaded')
                    const href = URL.createObjectURL(response.data)

                    const link = document.createElement('a')
                    link.href = href
                    link.setAttribute('download', name)
                    document.body.appendChild(link)
                    link.click()

                    document.body.removeChild(link)
                    URL.revokeObjectURL(href)
                })
                .catch((e) => {
                    console.log(e)
                })
        },
        handleDelete(index) {
            if (!this.permissions.editOrCreate) {
                this.$notify(permissionError)
                return false
            }
            this.$http.projects
                .removeAdditionalDocument(index)
                .then((response) => {
                    this.getAdditionalFiles()
                    if (
                        response.data.message ===
                        'The project additional document has been deleted successfully'
                    ) {
                        this.toast(
                            'success',
                            'The additional file has been deleted from the project successfully',
                        )
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        },
        deleteProject() {
            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to delete project "${this.project.name}", delete all related scopes and revoke all related tasks and contract assignments?`,
                    cancelText: 'Cancel',
                    confirmText: 'Yes, delete',
                    reverse: true,
                })
                .then((res) => {
                    if (res) {
                        this.loading = true
                        this.$http.projects
                            .deleteProject(this.$route.params.id)
                            .then((res) => {
                                this.toast('success', res.data.message)
                                this.$router.push({name: 'ProjectsList'})
                            })
                            .catch(({response}) =>
                                this.toast('success', response.data.message),
                            )
                            .finally(() => (this.loading = false))
                    }
                })
        },
        saveFifthStep() {
            return this.$http.projects
                .saveFifthStep(this.$route.params.id, {
                    project_name: this.project.name,
                })
                .then(() => this.fetchData())
                .catch(({response}) => {
                    if (response.status === 422) {
                        this.validationErrors['name'] = Object.values(
                            this.getErrors(response.data.errors),
                        )[0]
                        this.validator.scrollToErrors()
                    } else {
                        this.toast('error', response.data.message)
                    }
                })
        },
        uploadFile(e) {
            if (!this.permissions.editOrCreate) {
                this.$notify(permissionError)
                return false
            }

            this.uploading = true
            let formData = new FormData()

            for (const file of e.target.files) {
                formData.append('files[]', file)
            }

            this.additionalFilesChanged = true

            ProjectsAdditionalDocuments
                .store(this.$route.params.id, formData)
                .then(response => {
                    this.toast('success', 'The additional file has been uploaded to the project successfully');
                    this.additionalFiles = response.data;
                })
                .catch(errors => this.toast('error', errors.shift()))
                .finally(() => (this.uploading = false))
        },
        getAdditionalFiles() {
            this.$http.projects
                .getAdditionalDocuments(this.$route.params.id)
                .then((res) => {
                    this.additionalFiles = res.data.data
                })
                .catch(({response}) => this.toast('error', response.data.message))
        },
        openTemplates() {
            if (this.folderList.length !== 0) {
                this.showTemplates = !this.showTemplates
                this.showTemplatesText === 'View Packages'
                    ? (this.showTemplatesText = 'Hide Packages')
                    : (this.showTemplatesText = 'View Packages')
            }
        },
        validateFolderList() {
            if (this.folderList.length) {
                this.collectionError = false
                this.errorText = ''
            } else if (this.validationErrors.selectCollection) {
                this.collectionError = true
                this.errorText = this.validationErrors.selectCollection
            } else if (!this.folderList.length && this.defaultName) {
                this.collectionError = true
                this.errorText =
                    'The collection should contain at least one folder with templates'
            }
        },
        validateField(f) {
            let val = f === 'name' ? this.project['name'] : this.selectCollection
            this.validationErrors[f] = this.validator.validate(f, val)
            this.validateFolderList()
        },
        getProject() {
            if (this.$route.params.id) {
                // this.selectCollection = null
                this.loading = true
                this.$http.projects
                    .getProjectInfo(this.$route.params.id)
                    .then((res) => {
                        this.selectCollection = res.data.data.template_collection_id
                        this.collectionChanged = this.selectCollection
                        this.project = res.data.data
                        this.projectName = this.project.name
                        this.step = this.project.step
                    })
                    .catch()
                    .finally(() => (this.loading = false))
            }
        },
        fetchData() {
            this.loading = true
            this.$http.template
                .getCollectionList()
                .then((res) => {
                    this.collection = res.data.data.map((item) => ({
                        value: item.id,
                        name: item.name,
                    }))
                    this.getProject()
                })
                .catch()
                .finally(() => (this.loading = false))
        },
        redirectNext() {
            this.$router.push({
                name: 'ProjectScopesList',
                params: {
                    id: this.$route.params.id,
                    create: this.$route.params.create,
                },
            })
        },
        checkPrimaryTemplate() {
            let arr = []
            this.folderList.map((i) => i.primary_contracts.map((i) => arr.push(i.template_id)))
            if (!arr.length) {
                let name = this.collection.find((el) => el.value === this.selectCollection)?.name
                this.$notify({
                    type: 'info',
                    content: `The package "${name}" must have at least one primary package document to be assigned to the project`
                })
            }

            return arr.length > 0
        },
        async save(next = false) {
            if (!this.checkPrimaryTemplate()) return
            const validationResult = this.validator.validateAll({
                name: this.project.name,
                selectCollection: this.selectCollection,
            })
            this.validateField('selectCollection')
            if (validationResult.hasErrors) {
                this.validationErrors = validationResult.validationErrors
                this.validator.scrollToErrors()
                return false
            }

            this.additionalFilesChanged = false
            this.projectName = this.project.name
            await this.$http.projects
                .saveProjectCollectionList({
                    project_id: this.$route.params.id,
                    collection_id: this.selectCollection,
                    folders: this.folderList.map((i) => ({
                        id: i.folder_id,
                        primary_templates_id: i.primary_contracts.map((i) => i.template_id),
                        secondary_templates_id: i.secondary_contracts.map(
                            (i) => i.template_id,
                        ),
                    })),
                })
                .then(() => {
                    !this.collectionWasChanged
                        ? this.toast('success', 'Template collection has been changed successfully')
                        : this.toast('success', 'Template collection has been attached to project');

                    this.collectionChanged = this.selectCollection

                    this.setFoldersFromServer()
                    this.saveFifthStep()

                    if (next) {
                        this.redirectNext()
                    }
                })
                .catch(({response}) => {
                    this.validationErrors = response.data.errors
                        ? this.getErrors(response.data.errors)
                        : {}
                })
        },
        clearErrors() {
            this.validationErrors = {}
            this.collectionError = false
            this.errorText = ''
        },
        async handleCancel(back = false) {
            if (this.isChanged || !this.projectNewName) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: 'You have unsaved changes. <br /> Are you sure you want to cancel them?',
                        cancelText: 'Cancel',
                        confirmText: 'Ok',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.fetchData()
                            this.clearErrors()
                        }
                        this.openTemplates()
                    })
            } else {
                this.fetchData()
                this.clearErrors()
            }
        },
        deleteItem(folderId, pivotId = undefined) {
            const folderName = this.folderList.find((id) => id.folder_id === folderId)
            const primaryList = folderName.primary_contracts.find(
                (id) => id.pivot_id === pivotId,
            )
            const secondaryList = folderName.secondary_contracts.find(
                (id) => id.pivot_id === pivotId,
            )
            if (pivotId !== undefined) {
                this.$http.projects.removeTemplateBuilder({
                    project_id: this.$route.params.id,
                    pivot_id: pivotId,
                })
            }
            if (primaryList) {
                this.toast(
                    'success',
                    `Task ${primaryList.task_name} document has been removed from the project`,
                )
            } else if (secondaryList) {
                this.toast(
                    'success',
                    `Task ${secondaryList.task_name} document has been removed from the project`,
                )
            }
            this.folderList.forEach((item, fIndex) => {
                if (item.folder_id === folderId) {
                    item.primary_contracts.forEach((template, tIndex) => {
                        if (template.pivot_id === pivotId) {
                            this.folderList[fIndex]['primary_contracts'].splice(tIndex, 1)
                        }
                    })
                    item.secondary_contracts.forEach((template, tIndex) => {
                        if (template.pivot_id === pivotId) {
                            this.folderList[fIndex]['secondary_contracts'].splice(tIndex, 1)
                        }
                    })
                }
            })

            this.folderList = JSON.parse(JSON.stringify(this.folderList))
        },
        addTemplate(data) {
            this.folderList.forEach((item, index) => {
                if (item.folder_id === data.folderId) {
                    this.folderList[index][data.template.flow].unshift(data.template)
                }
            })
            this.folderList = JSON.parse(JSON.stringify(this.folderList))
        },
        moveTemplate({template_id, folder_id, flow}) {
            let folderList = JSON.parse(JSON.stringify(this.folderList))
            const oldType =
                flow === 'primary' ? 'secondary_contracts' : 'primary_contracts'
            const newType =
                flow === 'primary' ? 'primary_contracts' : 'secondary_contracts'

            const index = folderList.findIndex((i) => i.folder_id == folder_id)
            const templete_index = folderList[index][oldType].findIndex(
                (i) => i.template_id == template_id,
            )

            if (
                oldType === 'primary_contracts' &&
                newType === 'secondary_contracts'
            ) {
                folderList[index][oldType][templete_index]['is_main'] = false
            }

            folderList[index][newType].push(
                folderList[index][oldType][templete_index],
            )

            folderList[index][oldType].splice(templete_index, 1)

            this.folderList = folderList
        },
        setRules() {
            this.validator = validator({
                name: [rules.required, rules.strMax(255)],
                selectCollection: [rules.required],
            })
        },
    },
}
