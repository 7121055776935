<template>
  <div class="divider line razor" :class="{lineContent: content}">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: 'CDivider',
  props: {
    content: { type: [String, Number], default: '' },
  },
}
</script>

<style scoped lang="scss">
.divider {
  display: flex;
  flex: 1;
  font-size: 0.9rem;

  &:before,
  &:after {
    content: '';
    flex: 1;
  }
}

//.lineContent {
//  &:after {
//    margin-left: 1em;
//  }
//
//  &:before {
//    margin-right: 1em;
//  }
//}

.line {
  align-items: center;

  &:before {
    margin-left: 1em;
  }

  &:after {
    margin-right: 1em;
  }

  &:before,
  &:after {
    margin-top: 0;
    margin-bottom: 0;
    height: 2px;
  }
}

.razor {
  &:before,
  &:after {
    box-shadow: 0 0.5px 0 #DEE4E8; // Chrome
    // border-bottom: 0.5px solid #DEE4E8; // Safari
  }
}
</style>
