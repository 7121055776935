<template>
  <CDropdown class="mb-1">
    <CDropdownToggle v-if="isMobile" class="clear">
      <CIcon name="cilPlus" />
    </CDropdownToggle>
    <CDropdownToggle
      v-else
      color="primary"
      variant="outline"
    >
      Add Document
    </CDropdownToggle>

    <CDropdownMenu>
      <CDropdownItem @click="handleChange('modalLibraryPrimary')">
        Add from library to Primary
      </CDropdownItem>
<!--      <CDropdownItem @click="handleChange('modalPCPrimary')"> Add from device to Primary </CDropdownItem>-->
      <CDropdownItem @click="handleChange('modalLibraryFlowDown')">
        Add from library to Flow Down
      </CDropdownItem>
<!--      <CDropdownItem @click="handleChange('modalPCFlowDown')"> Add from device to Flow Down </CDropdownItem>-->
    </CDropdownMenu>
  </CDropdown>
  <FromLibrary
    v-model="modalLibraryPrimary"
    :except-library-type="['label']"
    @addTemplate="template => addTemplate({...template, flow: 'primary_contracts'})"
  />
  <FromPC
    v-model="modalPCPrimary"
    :default-name="defaultName"
    type="Acknowledge"
    @update="template => addTemplate({...template, flow: 'primary_contracts'})"
  />
  <FromLibrary
    v-model="modalLibraryFlowDown"
    :except-library-type="['label']"
    @addTemplate="template => addTemplate({...template, flow: 'secondary_contracts'})"
  />
  <FromPC
    v-model="modalPCFlowDown"
    :default-name="defaultName"
    type="Request documents"
    @update="template => addTemplate({...template, flow: 'secondary_contracts'})"
  />
</template>

<script>
import FromPC from '@/components/Template/Add/FromPC'
import FromLibrary from '@/components/Template/Add/FromLibrary'
import { updatePlan, permissionError } from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'

export default {
  name: 'ContractBulkAction',
  components: { FromPC, FromLibrary },
  props: {
    folderId: { type: Number, default: null },
    defaultName: { type: String, default: '' },
  },
  emits: ['addTemplate', 'update'],
  computed: {
    isMobile(){
      return this.windowWidth < 576
    },
  },
  data() {
    return {
      modalPCPrimary: false,
      modalLibraryPrimary: false,
      modalPCFlowDown: false,
      modalLibraryFlowDown: false,
      windowWidth: window.innerWidth,
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {
    async handleChange(modelName) {
      if (await this.checkPermission()) {
        this[modelName] = true
      }
    },
    async checkPermission() {
      const check = await Gate.can('editOrCreate', 'project', this.$route.params.id)
      if (!check) {
        if (this.$store.getters.isSubPlan) this.$notify(updatePlan)
        if (this.$store.getters.isGeneralPlan) this.$notify(permissionError)
      }
      return check
    },
    addTemplate(template) {
      this.$emit('addTemplate', template)
    },
    updateData(template) {
      this.$emit('update', template)
    },
  },
}
</script>

<style lang="scss" scoped>
.clear {
  box-shadow: none !important;

  &:after {
    display: none;
  }
}
</style>
